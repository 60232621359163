import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import useFormatter from "@/use/formatter";
import useArrayHelpers from "@/use/arrayHelpers";
import _ from "lodash";
import { PaymentCalculationViewModel } from "@/models/viewModels";

const { formatNumber } = useFormatter();
const { groupBy } = useArrayHelpers();

const refiRatesSelfSelectModule: Module<any, any> = {
  namespaced: true,
  state: {
    primaryApplicantSelectedCreditScoreRange: {},
    coApplicantSelectedCreditScoreRange: {},
    selectedCreditScoreRange: {},
    currentApplicantTypeId: 0 as number,
    currentApplicantTypeName: "" as string,
    sortedAvailableRateOptions: {} as any,
    sortedAvailableTerms: [] as any[],
    availableRateOptions: [] as any[],
    creditScoreRanges: [] as any[],
    fixedRateCreditScoreRanges: [] as any[],
    variableRateCreditScoreRanges: [] as any[],
    calculatedPaymentOptions: [] as PaymentCalculationViewModel[],
    calculatingEstimatedPayments: false as boolean,
    estimatedLoanAmount: 0 as number,
  },
  mutations: {
    SET_SELECTED_CREDIT_SCORE_RANGE(state: any, selectedCreditScoreRange: any) {
      state.selectedCreditScoreRange = selectedCreditScoreRange;
    },
    SET_CURRENT_APPLICANT_TYPE_ID(state: any, currentApplicantTypeId: number) {
      state.currentApplicantTypeId = currentApplicantTypeId;
    },
    SET_CURRENT_APPLICANT_TYPE_NAME(state: any, currentApplicantTypeName: string) {
      state.currentApplicantTypeName = currentApplicantTypeName;
    },
    SET_CREDIT_SCORE_RANGES(state: any, creditScoreRanges: any) {
      state.creditScoreRanges = creditScoreRanges;
    },
    SET_FIXED_RATE_CREDIT_SCORE_RANGES(state: any, creditScoreRanges: any) {
      state.fixedRateCreditScoreRanges = creditScoreRanges;
    },
    SET_VARIABLE_RATE_CREDIT_SCORE_RANGES(state: any, creditScoreRanges: any) {
      state.variableRateCreditScoreRanges = creditScoreRanges;
    },
    SET_CALCULATED_PAYMENT_OPTIONS(state: any, calculatedPaymentOptions: PaymentCalculationViewModel[]) {
      state.calculatedPaymentOptions = calculatedPaymentOptions;
    },
    SET_CALCULATING_ESTIMATED_PAYMENTS(state: any, flag: boolean) {
      state.calculatingEstimatedPayments = flag;
    },
    SET_ESTIMATED_LOAN_AMOUNT(state: any, amount: number) {
      state.estimatedLoanAmount = amount;
    },
    RESET_ALL_STATES(state: any) {
      state.primaryApplicantSelectedCreditScoreRange = {};
      state.coApplicantSelectedCreditScoreRange = {};
      state.selectedCreditScoreRange = {};
      state.currentApplicantTypeId = 0;
      state.currentApplicantTypeName = "";
      state.sortedAvailableRateOptions = {};
      state.sortedAvailableTerms = [];
      state.availableRateOptions = [];
      state.creditScoreRanges = [];
      state.fixedRateCreditScoreRanges = [];
      state.variableRateCreditScoreRanges = [];
      state.calculatedPaymentOptions = [];
      state.calculatingEstimatedPayments = false;
      state.estimatedLoanAmount = 0;
    },
  },
  actions: {
    setCreditScoreRanges({ commit }, payload) {
      commit("SET_CREDIT_SCORE_RANGES", payload);
    },
    setCalculatingEstimatedPayments({ commit }, payload) {
      commit("SET_CALCULATING_ESTIMATED_PAYMENTS", payload);
    },
    async calculatePayments({ state, commit, dispatch }, payload: any) {
      dispatch("setCalculatingEstimatedPayments", true);

      const request = {
        applicantId: payload?.applicantId,
        estimatedLoanAmount: payload?.estimatedLoanAmount,
				ficoMin: payload?.ficoMin,
      };

      const payments = await $api.refi.calculatePayments(payload?.applicantId, request);

      if (payments?.length > 0 && !payments?.error) {
        const sortedPaymentsAsc = payments?.sort(
          (a: PaymentCalculationViewModel, b: PaymentCalculationViewModel) =>
            parseInt(a?.termYears) - parseInt(b?.termYears),
        );
        commit("SET_CALCULATED_PAYMENT_OPTIONS", sortedPaymentsAsc);
      } else {
        commit("SET_CALCULATED_PAYMENT_OPTIONS", []);
      }

      dispatch("setCalculatingEstimatedPayments", false);
    },
  },
  getters: {
    SortedAvailableRateOptions: (state: any): any => {
      if (store?.state?.program?.pricingTiers?.length > 0) {
        const availableRates = store?.state?.program?.pricingTiers
          .filter((i: any) => i.applicantType.id === state.currentApplicantTypeId)
          .filter((k: any) => k.ficoMin === state.selectedCreditScoreRange?.min)
          .map((option: any) => {
            // Fixed Rate
            if (option.rate.rateType.name === "Fixed") {
              const fixedInterestRate = option.rate.margin / 100;

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: fixedInterestRate,
                rateTypeName: option.rate.rateType.name,
                rate: `${formatNumber(fixedInterestRate * 100)}%`,
              };
            } else if (option.rate.rateType.name === "Variable") {
              const indexRate = option.rate.indexType.rate;
              const variableRate = option.rate.margin + indexRate;

              let interestRate = 0 as number;
              if (variableRate < option.rate.floor) {
                interestRate = option.rate.floor;
              } else if (variableRate > option.rate.ceiling) {
                interestRate = option.rate.ceiling;
              } else {
                interestRate = variableRate;
              }

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: interestRate / 100,
                rateTypeName: option.rate.rateType.name,
                rate: `${formatNumber((interestRate / 100) * 100)}%`,
              };
            } else
              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: option.rate.margin / 100,
                rateTypeName: option.rate.rateType.name,
                rate: `${formatNumber((option.rate.margin / 100 / 100) * 100)}%`,
              };
          })
          .sort((a: any, b: any) => (a.rateTypeName > b.rateTypeName ? 1 : b.rateTypeName > a.rateTypeName ? -1 : 0));
        return groupBy(availableRates, "termInYears");
      } else {
        return [];
      }
    },
    AvailableRateTermOptions: (state: any): any => {},
    AvailableRateOptions: (state: any): any => {
      if (store?.state?.program?.pricingTiers?.length > 0) {
        return store?.state?.program?.pricingTiers
          .filter((i: any) => i.applicantType.id === state.currentApplicantTypeId)
          .filter((k: any) => k.ficoMin === state.selectedCreditScoreRange?.min)
          .map((option: any) => {
            // Fixed Rate
            if (option.rate.rateType.name === "Fixed") {
              const fixedInterestRate = option.rate.margin / 100;

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: fixedInterestRate,
                rateTypeName: option.rate.rateType.name,
                rate: `${formatNumber(fixedInterestRate * 100)}%`,
              };
            } else if (option.rate.rateType.name === "Variable") {
              const indexRate = option.rate.indexType.rate;
              const variableRate = option.rate.margin + indexRate;

              let interestRate = 0 as number;
              if (variableRate < option.rate.floor) {
                interestRate = option.rate.floor;
              } else if (variableRate > option.rate.ceiling) {
                interestRate = option.rate.ceiling;
              } else {
                interestRate = variableRate;
              }

              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: interestRate / 100,
                rateTypeName: option.rate.rateType.name,
                rate: `${formatNumber((interestRate / 100) * 100)}%`,
              };
            } else
              return {
                max: option.ficoMax,
                min: option.ficoMin,
                term: option.term.months,
                termInYears: option.term.years,
                interestRate: option.rate.margin / 100,
                rateTypeName: option.rate.rateType.name,
                rate: `${formatNumber((option.rate.margin / 100 / 100) * 100)}%`,
              };
          })
          .sort((a: any, b: any) => (a.rateTypeName > b.rateTypeName ? 1 : b.rateTypeName > a.rateTypeName ? -1 : 0));
      }
      return [];
    },
    AvailableRatePaymentOptionsByCreditScoreRange: (state: any): any => {
      if (
        state.calculatedPaymentOptions?.length > 0 &&
        state.selectedCreditScoreRange?.min > 0 &&
        state.selectedCreditScoreRange?.max > 0
      ) {
        return state.calculatedPaymentOptions?.filter(
          (payment: any) =>
            payment.ficoMin === state.selectedCreditScoreRange?.min &&
            payment.ficoMax === state.selectedCreditScoreRange?.max,
        );
      } else {
        return [];
      }
    },
  },
};
export default refiRatesSelfSelectModule;
