import { createRouter, createWebHistory, RouteParams, RouteRecordRaw } from "vue-router";
import store from "@/store";
import Application from "@/views/Application.vue";
import ApplicationRefi from "@/views/ApplicationRefi.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/application",
  },
  {
    path: "/application",
    name: "Application",
    component: Application,
    beforeEnter: (to, from, next) => {
      const product = localStorage.getItem("product");
      const productTypeId = to.query.productTypeId;

      if (productTypeId === "1" || (!productTypeId && product === "1")) {
        to.matched[0].components = { default: Application };
        next();
      } else if (productTypeId === "2" || (!productTypeId && product === "2")) {
        to.matched[0].components = { default: ApplicationRefi };
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/application/:productTypeId",
    name: "ApplicationRefi",
    component: () => import(/* webpackChunkName: "about" */ "../views/ApplicationRefi.vue"),
  },
  {
    path: "/invite/accept",
    name: "Invite",
    component: () => import(/* webpackChunkName: "about" */ "../views/Invite.vue"),
  },
  {
    path: "/return",
    name: "Return",
    component: () => import(/* webpackChunkName: "about" */ "../views/Return.vue"),
  },
  {
    path: "/ecommunications",
    name: "ecommunications",
    component: () => import(/* webpackChunkName: "protected" */ "../views/ECommunications.vue"),
    meta: {
      isPublic: true,
      title: "ECommunications",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import(/* webpackChunkName: "protected" */ "../views/TermsAndConditions.vue"),
    meta: {
      isPublic: true,
      title: "Terms & Conditions",
    },
  },
  {
    path: "/mobileCommunications",
    name: "mobileCommunications",
    component: () => import(/* webpackChunkName: "protected" */ "../views/MobileCommunications.vue"),
    meta: {
      isPublic: true,
      title: "Mobile Communications",
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import(/* webpackChunkName: "protected" */ "../views/PrivacyPolicy.vue"),
    meta: {
      isPublic: true,
      title: "Privacy Policy",
    },
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () => import(/* webpackChunkName: "protected" */ "../views/Accessibility.vue"),
    meta: {
      isPublic: true,
      title: "Accessibility",
    },
  },
  {
    path: "/federalLoanDisclosure",
    name: "federalLoanDisclosure",
    component: () => import(/* webpackChunkName: "protected" */ "../views/FederalLoanDisclosure.vue"),
    meta: {
      isPublic: true,
      title: "Federal Loan Disclosure",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  console.log("router beforeEach", { to, from, store });
  // #TODO figure out how we will prevent users from changing productTypeIds
});

export default router;
